import React from "react";
import classNames from "classnames";
import * as styles from "./style.module.scss";

type Props = {
  className?: string;
  disabled?: boolean;
  label: any;
  color?: "blue" | "grey";
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export default function SecondaryButton({
  className = "",
  label,
  disabled = false,
  color = "blue",
  onClick = () => {},
}: Props) {
  return (
    <>
      <button
        type="button"
        disabled={disabled}
        className={classNames(className, styles.buttonStyle, {
          [styles.grey]: color === "grey",
        })}
        onClick={onClick}
      >
        {label}
      </button>
    </>
  );
}
