import React from "react";
import instagram from "../../assets/icons/instagram.svg";
import facebook from "../../assets/icons/facebook.svg";
import check from "../../assets/icons/check.svg";
import arrow from "../../assets/icons/arrow.svg";
import logoClaim from "../../assets/icons/logoClaim.svg";

export type IconType =
  | "facebook"
  | "instagram"
  | "check"
  | "arrow"
  | "logoClaim";

type IconProps = {
  name: IconType;
  width?: number;
  height?: number;
  className?: string;
};

const Icon = ({ name, width, height, className }: IconProps) => {
  if (!name) {
    return null;
  }

  const icons = {
    facebook,
    instagram,
    check,
    arrow,
    logoClaim,
  };

  const CurrentIcon = icons[name];

  if (!CurrentIcon) return null;

  return <CurrentIcon width={width} height={height} className={className} />;
};

export default Icon;
