import React from "react";
import classNames from "classnames";

import DynamicImage from "../DynamicImage";
import * as styles from "./styles.module.scss";

type Props = {
  image: string;
  isVisible: boolean;
};

const WallImage: React.FC<Props> = ({ image, isVisible = false }) => (
  <div className={classNames(styles.img, { [styles.show]: isVisible })}>
    <DynamicImage imageName={image} />
  </div>
);

export default WallImage;
