import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { MainContext } from "../../providers";
import WallImage from "../WallImage";

import * as styles from "./styles.module.scss";

type Props = {
  isIntro?: boolean;
};

const ResultWall: React.FC<Props> = ({ isIntro = false }) => {
  const { wallData } = useContext(MainContext);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {Array.from(Array(3).keys()).map((_, index) => (
          <div
            className={classNames(styles.wallContainer, styles[`wall${index}`])}
            key={index}
          >
            <>
              {wallData[index]?.map((items: any[]) =>
                items.map(item => (
                  <WallImage
                    key={item.image}
                    image={item.image}
                    isVisible={isIntro ? true : item.selected}
                  />
                ))
              )}
              <StaticImage
                src="../../assets/images/wall-bg.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Wand"
                objectFit="cover"
                className={styles.bg}
              />
            </>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultWall;
