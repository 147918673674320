import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

type Props = {
  imageName?: string;
};

const DynamicImage: React.FC<Props> = ({ imageName = "" }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const match = data.images.edges.find(
    ({ node }: { node: any }) => imageName === node.name
  );

  if (!match) return null;

  const {
    node: {
      name,
      childImageSharp: { gatsbyImageData },
    },
  } = match;
  return (
    <GatsbyImage
      image={gatsbyImageData}
      alt={name}
      objectFit="contain"
      className="w-full h-full"
    />
  );
};

export default DynamicImage;
